<template lang="pug">
transition(name='bg')
  .alert_box(v-show='visible')
    transition(name='con')
      .boxTransition(v-show='visible')
        .content(:style='"width:" + boxWidth + "rem;"')
          slot
          .close_btn(@click='hide')
    transition(name='bg')
      .bg(@click='hide' v-show='visible')
</template>

<script>
export default {
  name: 'AlertBox',
  props: {
    boxWidth: {
      type: Number,
      default: 6,
    },
  },
  data() {
    return {
      visible: false,
    }
  },
  methods: {
    show() {
      this.visible = true
    },
    hide() {
      this.visible = false
    },
  },
}
</script>

<style lang="stylus" scoped>
.bg-enter, .bg-leave-to
  opacity: 0

.bg-enter-to, .bg-leave
  opacity: 1

.bg-leave-active, .bg-enter-active
  transition: all 0.2s

.con-enter, .con-leave-to
  transform: translateY(-20%)

.con-enter-to, .con-leave
  transform: translateY(0)

.con-leave-active, .con-enter-active
  transition: all 0.2s

.alert_box
  position: fixed
  width: 100%
  // max-width 7.5rem
  height: 100vh
  top: 0
  left: 50%
  transform: translateX(-50%)
  text-align: center
  z-index: 999

  .boxTransition
    width: 100%
    // max-width 7.5rem
    height: 100vh
    position: absolute
    z-index: 3
    pointer-events: none

  .bg
    width: 100vw
    height: 100vh
    position: absolute
    left: 0
    top: 0
    z-index: 1
    background: rgba(0, 0, 0, 0.4)

  .content
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    background: #fff
    border-radius: 0.28rem
    z-index: 2
    pointer-events: all

    .close_btn
      position: absolute
      left: 50%
      transform: translateX(-50%)
      bottom: -1.2rem
      width: 1rem
      height: 1rem
      display: flex
      align-items: center
      justify-content: center
      background: url('../assets/img/icon_close.png') center center no-repeat
      background-size: 0.48rem 0.48rem
</style>
